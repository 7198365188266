<template>
  <div class="w-full h-full flex items-center justify-center">
    <div class="login-form">
      <div class="login-form-header flex flex-row items-center justify-between">
        <h3>로그인</h3>

        <div class="flex flex-row items-center">
          <span>회원이 아니신가요?</span>
          <router-link :to="{ name: 'Signup' }">회원가입하기</router-link>
        </div>
      </div>

      <div class="login-form-content">
        <p class="content-title">
          유휴공간 임대 및 인테리어 비교견적 전문 플랫폼
        </p>

        <div class="login-input-form flex flex-col">
          <label>이메일</label>
          <input
            type="email"
            placeholder="이메일을 입력해주세요"
            v-model="email"
          />
        </div>

        <div class="login-input-form flex flex-col">
          <label>비밀번호</label>
          <input
            type="password"
            placeholder="비밀번호를 입력해주세요"
            v-model="password"
            @keyup.enter="onSubmit"
          />
        </div>

        <button
          @click="onSubmit"
          class="w-full flex justify-center items-center"
        >
          로그인 하기
        </button>
      </div>

      <div class="login-social flex flex-row items-center justify-between">
        <span>SNS 간편 로그인</span>

        <div class="social-items flex flex-row items-center">
          <img @click="loginWithNaver" src="@/assets/naver.png" alt="naver" />
          <GoogleLogin
            :params="googleParams"
            :onSuccess="onGoogleLoginSuccess"
            :onFailure="onGoogleLoginFail"
          >
            <img src="@/assets/google.png" alt="google" />
          </GoogleLogin>
          <img @click="loginWithKakao" src="@/assets/kakao.png" alt="kakao" />
        </div>
      </div>

      <div class="find-password flex flex-row items-center justify-end">
        <router-link :to="{ name: 'FindPassword' }">비밀번호 찾기</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import GoogleLogin from "vue-google-login";

export default {
  name: "Login",
  components: {
    GoogleLogin,
  },
  data() {
    return {
      email: "",
      password: "",
      googleParams: {
        client_id:
          "28231095760-0voognsg9ov4i1ie1kkr4fhskgaej53q.apps.googleusercontent.com",
      },
    };
  },
  mounted() {
    this.kakaoPrepare();
  },
  methods: {
    async onSubmit() {
      if (!this.email) {
        this.$toast.open({ message: "이메일을 입력해주세요", type: "error" });
      } else if (!this.password) {
        this.$toast.open({ message: "비밀번호를 입력해주세요", type: "error" });
      } else {
        const redirect = this.$route.query?.redirect;
        const data = {
          email: this.email,
          password: this.password,
          redirect,
        };
        await this.login(data);
        await this.me();
      }
    },
    async onGoogleLoginSuccess(googleUser) {
      var isComplete = false;

      console.log('onGoogleLoginSuccess');
      const { wc } = googleUser;
      console.log(googleUser);
      console.log(wc);
      if (wc != null) {
        isComplete = true;
        console.log('wc is not null');
        const { access_token } = wc;
        console.log('access_token : ' + access_token);
        await this.social({ site: "google", token: access_token });
      }

      if (!isComplete) {
        const { vc } = googleUser;
        console.log('check vc');
        console.log(vc);
        if (vc != null) {
          isComplete = true;
          console.log('vc is not null');
          const { access_token } = vc;
          console.log('access_token : ' + access_token);
          await this.social({ site: "google", token: access_token });
        }
      }
    },
    onGoogleLoginFail() {
      this.$toast.open({ message: "구글 로그인 실패", type: "error" });
    },
    kakaoPrepare() {
      const scriptId = "kakao_login";
      const isExist = !!document.getElementById(scriptId);
      if (!isExist) {
        const script = document.createElement("script");
        script.src = "https://developers.kakao.com/sdk/js/kakao.min.js";
        script.onload = () => this.kakaoInit(this);
        script.id = scriptId;
        document.body.appendChild(script);
      } else this.kakaoInit(this);
    },
    kakaoInit() {
      window.Kakao.init("ec2c3442aca22230d410e8fcae58820a");
    },
    loginWithKakao() {
      const params = {
        redirectUri: (process.env.VUE_APP_IS_LOCAL_SERVER == 1 ? process.env.VUE_APP_SNS_LOGIN_BASEURL_LOCAL : process.env.VUE_APP_SNS_LOGIN_BASEURL_SERVER) + "/kakao",
      };
      window.Kakao.Auth.authorize(params);
    },
    loginWithNaver() {
      const naverClientId = "h7S46A5asnUwEgrWRIxl";
      const naverCallback = (process.env.VUE_APP_IS_LOCAL_SERVER == 1 ? process.env.VUE_APP_SNS_LOGIN_BASEURL_LOCAL : process.env.VUE_APP_SNS_LOGIN_BASEURL_SERVER) + "/naver";
      const url = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${naverClientId}&redirect_uri=${naverCallback}`;
      window.location.replace(url);
    },
    ...mapActions("user", ["login", "me", "social"]),
  },
};
</script>

<style scoped lang="scss">
.login-form {
  margin-top: 225px;
  margin-bottom: 130px;
  border: 1px solid #d7d7d7;
  width: 588px;
  padding-top: 75px;
  padding-bottom: 61px;

  .login-form-header {
    margin-left: 70px;
    margin-right: 66px;
    padding-bottom: 37px;
    border-bottom: 1px solid #dddee1;

    h3 {
      font-size: 30px;
      font-weight: 700;
      color: #333333;
      letter-spacing: -1.2px;
    }

    span {
      font-size: 13px;
      font-weight: 700;
      color: #636363;
      letter-spacing: -0.13px;
      margin-right: 6px;
    }

    a {
      font-size: 13px;
      font-weight: 700;
      letter-spacing: -0.13px;
      color: #283aef;
    }
  }

  .login-form-content {
    padding-left: 94px;
    padding-right: 94px;
    padding-top: 38px;

    .content-title {
      color: #333;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: -0.56px;
      margin-bottom: 29px;
    }

    .login-input-form {
      margin-bottom: 21px;

      label {
        padding-left: 13px;
        color: rgba(40, 58, 239, 0.8);
        font-size: 15px;
        font-weight: 500;
        letter-spacing: -0.15px;
      }

      input {
        height: 44px;
        border-bottom: 1px solid #d7d7d7;
        padding-top: 13px;
        padding-bottom: 17px;
        font-weight: 500;
        font-size: 14px;
        letter-spacing: -0.14px;
        padding-left: 14px;
        padding-right: 14px;

        &::placeholder {
          color: #dddee1;
        }
      }
    }

    button {
      background-color: #283cf0;
      border-radius: 10px;
      height: 59px;
      color: white;
      font-weight: 700;
      font-size: 16px;
      letter-spacing: -0.8px;
    }
  }

  .login-social {
    margin-top: 29px;
    padding-left: 93px;
    padding-right: 98px;

    span {
      font-size: 14px;
      color: #6e6e6e;
      font-weight: 700;
      letter-spacing: -0.14px;
    }

    .social-items {
      img {
        cursor: pointer;
        margin-left: 10px;
      }
    }
  }

  .find-password {
    margin-top: 22px;
    padding-right: 94px;

    a {
      font-size: 14px;
      font-weight: 500;
      color: #8e8e8e;
      letter-spacing: -0.14px;
      text-decoration: underline;
    }
  }
}

@media (max-width: 639px) {
  .login-form {
    margin-top: 100px;
    margin-bottom: 50px;
    border: none;
    padding-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    .login-form-header {
      margin-left: 0;
      margin-right: 0;
      padding-bottom: 20px;
      h3 {
        font-size: 20px;
      }
      span {
        font-size: 11px;
      }
      a {
        font-size: 11px;
      }
    }
    .login-form-content {
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 25px;
      .content-title {
        font-size: 12px;
      }
      .login-input-form {
        label {
          font-size: 12px;
        }
        input {
          height: 35px;
          font-size: 12px;
        }
      }
      button {
        height: 40px;
        font-size: 13px;
      }
    }
    .login-social {
      padding-left: 20px;
      padding-right: 20px;
      span {
        font-size: 12px;
      }
      .social-items {
        img {
          width: 35px;
          object-fit: contain;
        }
      }
    }
    .find-password {
      padding-right: 20px;
      a {
        font-size: 12px;
      }
    }
  }
}
</style>
